import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

// breadcrumbs
const breadCrumbLevels = {
  Home: "/",
  "Analytics Consulting": "/nl/analytics-consulting",
  "Google Analytics Installatie": "/nl/google-analytics-installatie"
};

// hreflang data
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en/google-analytics-setup"
  },
  {
    hreflang: "de",
    href: "/de/google-analytics-einrichten"
  },
  {
    hreflang: "se",
    href: "/se/google-analytics-setup"
  },
  {
    hreflang: "da",
    href: "/da/google-analytics-opsætning"
  },
  {
    hreflang: "no",
    href: "/no/google-analytics-oppsett"
  },
  {
    hreflang: "nl",
    href: "/nl/google-analytics-installatie"
  },
  {
    hreflang: "x-default",
    href: "/en/google-analytics-setup"
  }
];

const AnalyticsSetup = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Google Analytics installeren vanaf nul! [${currentYear} Gids]`}
        description="Gids over hoe je een moderne Google Analytics setup configureert. Doelen instellen, event tracking en best practices. Tutorial van een gecertificeerde Google Analytics consultant."
        lang="nl"
        image="google-analytics-setup-realtime-report-hero"
        alternateLangs={alternateLangs}
        canonical="/nl/google-analytics-installatie"
      />
      <MainContent article>
        <ImgScreenshot
          src="google-analytics-einrichten/google-analytics-setup-realtime-report-hero.png"
          alt="man die werkt aan Google Analytics setup met laptop"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Google Analytics Installatie Gids</H>
        <p>
          Een van de eerste taken voor een nieuwe website is het instellen van Google Analytics. Na al die tijd en
          moeite die je hebt gestoken in het bouwen van een website, wil je natuurlijk het gebruikersgedrag meten en analyseren. Je
          kunt vragen hebben zoals:
        </p>

        <ul>
          <li>Hoe presteert mijn content?</li>
          <li>Welke productcategorie is het populairst?</li>
          <li>Hoeveel conversies heeft mijn Facebook-campagne opgeleverd?</li>
          <li>Bekijken bezoekers de video die ik heb gemaakt?</li>
          <li>Zijn er pagina's die bijzonder slecht presteren?</li>
          <li>Delen bezoekers iets op sociale media?</li>
          <li>Haken mensen af tijdens het afrekenproces?</li>
        </ul>

        <p>
          Alle aannames over gebruikersgedrag en campagneprestaties moeten worden gevalideerd. <a href="https://w3techs.com/technologies/details/ta-googleanalytics" target="_blank">55% van alle websites op internet gebruikt Google Analytics</a> om dat te doen. In feite heeft het een <a href="https://w3techs.com/technologies/overview/traffic_analysis" target="_blank">marktaandeel van 84% in de markt voor verkeersanalysetools</a>, dus je hoeft je geen zorgen te maken dat je de verkeerde analysetool kiest.
        </p>
        <p>
          Een webanalysetool stelt je in staat beslissingen te nemen op basis van gegevens in plaats van te gokken. Dergelijke beslissingen kunnen bijvoorbeeld gaan over welk type content je moet investeren of hoeveel budget je aan advertentiecampagnes moet besteden.
        </p>
        <p>Daarom is het van essentieel belang om Google Analytics professioneel in te stellen, zodat je de juiste KPI's voor je analyse kunt volgen. Met zo'n setup heb je voldoende metrics beschikbaar om
          in te duiken.
        </p>
        <H as="h2">Soorten Google Analytics setups</H>
        <p>
          Het type website is een belangrijke factor voor een Google Analytics setup:
          <br />
          Run je een online winkel of verkoop je producten? <br />
          Dan
          e-commerce analytics is de juiste keuze. Het is meer product- en transactiegericht en zal je
          waardevolle inzichten geven die je niet wilt missen.
        </p>
        <p>
          Als je een contentgerichte site over je merk beheert, dan is een typische Google Analytics tracking setup de
          juiste keuze. <br />
          Je kunt gebruikersinteracties volgen met <Link to="/nl/event-tracking">event tracking</Link> en belangrijke website-KPI's volgen met{" "}
          <Link to="/nl/google-analytics-installatie#hoe-doelen-instellen-in-google-analytics">Google Analytics doelen</Link>.
        </p>

        <p>
          E-commerce analytics setups vereisen meer gegevens over producten en transacties. Daarom is de configuratie
          iets complexer en vereist het aangepaste JavaScript om een zogenaamde{" "}
          <Link to="/nl/data-laag">data laag</Link>
          te bouwen. <br />Een data layer is in feite een opslag van gegevens die informatie over elke pagina bevat. Google
          Analytics zal het herkennen en gebruiken wanneer het beschikbaar is.
        </p>
        <p>
          Maak je voorlopig geen zorgen als je beseft dat je Google Analytics voor een e-commerce site moet instellen. Je kunt
          de e-commerce functionaliteit later activeren en de data layer uitbreiden zodra je er klaar voor bent. <br />
          Tot die tijd blijven e-commerce rapporten leeg, maar andere rapporten zullen prima werken.
        </p>

        <H as="h2">Waar haal je de Google Analytics Tracking Code?</H>
        <p>
          Bezoek{" "}
          <a href="https://analytics.google.com" target="_blank" rel="noopener">
            analytics.google.com
          </a>{" "}
          en klik op de knop <strong>Aanmelden</strong> om een Google Analytics-account te maken. Je krijgt de Google
          Analytics trackingcode, zodra je de onderstaande stappen volgt.
        </p>
        <ImgScreenshot
          src="google-analytics-setup/singup-for-google-analytics-tracking-code.png"
          alt="Google Analytics aanmeldscherm screenshot"
          className="article-img"
        />
        <ul>
          <li>
            Nadat je op de knop <strong>aanmelden</strong> hebt geklikt, ga je verder en kies je een property naam en URL
            gevolgd door je branche en tijdzone.{" "}
          </li>
          <li>
            Ik raad aan om <strong>alle gegevensdelingsopties uit te schakelen</strong> als je niet volledig op de hoogte bent van je
            privacybeleid. Maar dat betekent dat Google <strong>geen</strong> rapporten over demografie en interesses van het publiek met je zal delen. <br />
            Als je die rapporten wilt gebruiken, moet je mogelijk je privacybeleid bijwerken. Dit is om gebruikers te informeren dat je
            gegevens over hun browsegedrag deelt met Google.
          </li>
          <li>
            Nadat je klaar bent, ontvang je je <strong>Google Analytics tracking ID</strong> (bijv.{" "}
            <em>UA-141112345-1</em>). Daarbij ontvang je ook de code snippet om in de broncode van
            al je pagina's te plaatsen.
          </li>
        </ul>
        <H as="h2">Hoe implementeer je Google Analytics?</H>
        <p>Er zijn over het algemeen twee manieren om Google Analytics te implementeren:</p>

        <ol>
          <li>
            Je plakt de Google Analytics trackingcode in de broncode van alle pagina's. Dit kun je pagina voor pagina doen of je plakt het in een gemeenschappelijk sjabloonbestand. Dat kan de header, navigatie of footer zijn die alle
            pagina's gemeen hebben.
            <br />
            <strong>Ik raad deze manier niet aan</strong> om Google Analytics te implementeren, tenzij je het snel en eenvoudig wilt doen en de analytics setup nooit meer wilt aanpassen. <br />
            De meeste tracking setups zijn sneller en eenvoudiger gedaan met een <Link to="/nl/tagbeheer">tag management systeem</Link>. Het is een eenvoudiger en flexibeler proces, dus het is de moeite waard om nog <em>een andere tool</em> aan de stack toe te voegen.
          </li>

          <li>
            Je gebruikt een tag management systeem, zoals Google Tag Manager, wat vrij populair is.
            <br />
            Er zijn ook andere alternatieven zoals <em>Adobe Launch</em>, <em>Tealium</em>, <em>Ensighten</em> of{" "}
            <em>Matomo</em> (open source). <br />
            Over het algemeen dienen ze allemaal hetzelfde doel en zijn ze erg vergelijkbaar. Je kunt Google Analytics implementeren met een
            niet-Google tag management systeem zonder zorgen. Google Tag Manager is gewoon degene die
            iedereen gemakkelijk toegang kan krijgen en heeft de meeste beschikbare bronnen online.
            <br />
            Als je er meer over wilt leren, bekijk dan mijn <Link to="/nl/google-tag-manager-setup">Google Tag Manager tutorial</Link>.
          </li>
        </ol>

        <H as="h2">Hoe implementeer je Google Analytics met Google Tag Manager?</H>
        <p>
          Wat betreft de <strong>implementatie van Google Analytics via Google Tag Manager,</strong> volg deze stappen:
        </p>

        <ul>
          <li>
            Bezoek{" "}
            <a href="https://tagmanager.google.com" target="_blank" rel="noopener">
              tagmanager.google.com
            </a>{" "}
            en maak een account aan.{" "}
          </li>{" "}
        </ul>
        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-create-account.png"
          alt="Google Tag Manager account aanmaken"
          className="article-img"
        />

        <ul>
          <li>
            Met het account maak je een container voor je website aan, dus zorg ervoor dat je <strong>Web</strong> selecteert voor het gebruik ervan:
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/google-analytics-setup-via-google-tag-manager-container-creation.png"
          alt="Google Tag Manager web container aanmaken screenshot"
          className="article-img"
        />

        <p>
          Daarna ontvang je de <strong>Google Tag Manager code snippet</strong> voor implementatie:
        </p>

        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-code-snippet.png"
          alt="Google Tag Manager code snippet"
          className="article-img"
        />

        <p>
          Voeg het eerste deel toe aan de <code>&lt;head&gt; </code>
          tag op alle pagina's van je website. Het andere deel wordt binnen de <code>&lt;body&gt;</code> tag toegevoegd.
        </p>
        <p>Na het toevoegen van de Google Tag Manager (GTM) snippet, kan de analytics setup eenvoudig worden geconfigureerd!</p>

        <ul>
          <li>
            Klik in GTM op <strong>tags</strong> in de linker navigatie en <strong>voeg een nieuwe tag toe</strong>. Er zal een overlay van de zijkant inschuiven en je kunt de tag een <strong>naam</strong> geven, bijvoorbeeld{" "}
            <em>Google Analytics Tracking</em>.
          </li>
          <li>Klik vervolgens op het tag configuratievak:</li>

          <ImgScreenshot
            src="google-analytics-setup/google-tag-manager-create-new-tag.png"
            alt="Google Tag Manager nieuwe tag aanmaken"
            className="article-img"
          />

          <li>
            Een lijst met tagtypes zal in het scherm schuiven. <br />
            Selecteer de <strong>Google Analytics - Universal Analytics</strong> tag en start de configuratie.
          </li>

          <ImgContainerFixed width="462px">
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-google-analytics-setup-tag-selection.png"
              alt="Google Tag Manager Google Analytics tag aanmaken"
              className="article-img"
            />
          </ImgContainerFixed>

          <li>
            Houd het <strong>Track Type</strong> op <strong>Pageview</strong>. <br />
          </li>
          <li>
            Maak dan een <strong>nieuwe Google Analytics instellingenvariabele</strong>.
          </li>

          <ImgScreenshot
            src="google-analytics-setup/google-tag-manager-google-analytics-setup-settings.png"
            alt="Google Tag Manager instellingenvariabele voor Google Analytics screenshot"
            className="article-img"
          />

          <li>
            Om de instellingenvariabele te configureren, geef je deze eerst een <strong>naam</strong>. Daarna plak je de{" "}
            <strong>Google Analytics Tracking ID</strong> (<em>UA-141112345-1</em>) erin, die je{" "}
            <Link to="#waar-haal-je-de-google-analytics-tracking-code">eerder hebt ontvangen</Link>.
          </li>

          <ImgScreenshot
            src="google-analytics-setup/google-tag-manager-google-analytics-setup-tracking-id.png"
            alt="Google Tag Manager Google Analytics setup tracking ID screenshot"
            className="article-img"
          />

          <li>
            Klik op <strong>opslaan</strong>.
          </li>
          <li>
            Terug in de tagconfiguratie, klik op de sectie <strong>triggering</strong> om een trigger te kiezen.
          </li>

          <li>
            Selecteer <strong>alle pagina's</strong>, omdat we willen dat de Google Analytics code snippet op alle pagina's draait.
          </li>

          <li>
            <strong>Opslaan</strong> de trigger evenals de tagconfiguratie en ga terug naar het Google Tag Manager overzicht.
          </li>

          <li>
            Klik op de blauwe <strong>submit</strong> knop in de rechterbovenhoek. Geef je eerste containerversie een{" "}
            <strong>naam</strong> en klik op <strong>publish</strong>. <br />
            Nu heb je Google Analytics succesvol geïmplementeerd met Google Tag Manager!
          </li>
        </ul>

        <p>
          Je zou na ongeveer een uur gegevens in je Google Analytics property moeten zien binnenkomen.
          <br />
          Je kunt ook naar het realtime rapport kijken om te zien of er al gegevens binnenkomen van je huidige bezoek.
        </p>
        <ImgScreenshot
          src="google-analytics-setup/google-analytics-realtime-tracking-overview.png"
          alt="Google Analytics realtime rapport overzicht"
        />

        <p>
          Merk je op dat je andere tools of scripts kunt implementeren en ze op <strong>alle pagina's</strong> kunt triggeren, in slechts enkele minuten? <br /> Ik hoop dat dit je de eenvoud en kracht van een tag management systeem laat inzien.
        </p>

        <H as="h2">Hoe stel je doelen in Google Analytics in?</H>
        <p>
          Nu je bezoekergegevens ontvangt, is het tijd om <strong>Google Analytics doelen</strong> in te stellen.
        </p>
        <p>De kans is groot dat je iets verkoopt op je website of dat er een formulier is dat mensen kunnen invullen. </p>
        <p>
          Normaal gesproken landen gebruikers na een bestelling op een <strong>bedankpagina</strong>. Hier staat meestal een bedankje voor de bestelling en worden enkele bestelgegevens vermeld. <br /> Kijk in de adresbalk en zie welke URL die pagina heeft.{" "}
        </p>
        <p>Bijvoorbeeld, het kan er zo uitzien:</p>
        <p>
          <code>https://www.jouwsite.com/bedankt.php</code>{" "}
        </p>
        <p>
          Laten we een doel instellen voor dit adres en tellen hoe vaak deze pagina wordt bezocht om aan te geven hoeveel bestellingen er hebben plaatsgevonden.
        </p>

        <ul>
          <li>
            Ga naar het Google Analytics <strong>admin-gedeelte</strong> met een overzicht van je account, property en
            weergave. Klik op <strong>doelen</strong> binnen de sectie <strong>weergave</strong>.
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/google-analytics-admin-area-goals.png"
          alt="Google Analytics admin-gedeelte doelen"
          className="article-img"
        />

        <ul>
          <li>
            <strong>Voeg</strong> een nieuw doel toe en selecteer <strong>custom</strong> in de doelconfiguratie. Klik dan op{" "}
            <strong>doorgaan</strong>.
          </li>
          <li>
            Geef je doel een <strong>naam</strong>, bijvoorbeeld <em>Nieuwsbrief inschrijving</em> of{" "}
            <em>Bestelformulier ontvangen</em> en selecteer het type als <strong>bestemming</strong>. Klik dan op{" "}
            <strong>doorgaan</strong>.
          </li>
          <li>
            Onder <strong>bestemming</strong> is <strong>gelijk aan</strong>, voeg het URL <strong>pad</strong> toe, dus in
            ons voorbeeld:
            <p>
              <code>/bedankt.php</code>
            </p>
            Je kunt ook slim zijn en <strong>Reguliere Expressie</strong> selecteren als het match type in het dropdownmenu.
            <br />
            Als je niet weet wat <strong>reguliere expressies</strong> zijn: Ze zijn een krachtige en handige manier om
            strings te matchen. Hier kun je leren hoe je ze in een uur of twee kunt gebruiken:{" "}
            <a href="https://regexone.com/" target="_blank" rel="noopener">
              RegexOne
            </a>
          </li>
          <li>
            Je kunt optioneel een <strong>waarde</strong> toekennen aan je doel. Ik zou het aanbevelen als je een numerieke
            waarde kunt toekennen aan elk van die bedanktpaginaweergaven.
            <br /> Er zijn meerdere manieren om dit te doen. De gemakkelijkste is om naar de maandelijkse inkomsten van die
            bedanktpagina's te kijken en deze te delen door het totale aantal paginaweergaven.
            <br />
            Dat geeft je het gemiddelde voor elke keer dat het doel wordt behaald.
          </li>
          <li>
            <strong>Stel een trechter in</strong> als de gebruikersreis bepaalde specifieke pagina's moet bereiken voor de
            conversie. Dit heeft veel zin voor formulieren die zijn opgesplitst in meerdere stappen. Je kunt later controleren of een
            deel van de bezoekers ergens tijdens het proces uit de trechter valt. <br />
            Je kunt stappen aan de trechter toevoegen door elke stap een <strong>naam</strong> en een{" "}
            <strong>pagina pad</strong> te geven (<em>zie hieronder</em>). <br />
            Merk op dat als je <strong>gelijk aan</strong> hebt gekozen in de bovenste bestemmingsdropdown, je hetzelfde
            match type moet gebruiken voor je trechterregels.
            <br />
            Of als je <strong>reguliere expressies</strong> hebt gekozen, moet je de regex-syntaxis volgen in je
            paginamatchingsregels.
          </li>
          <li>
            Klik op <strong>doel verifiëren</strong> voordat je opslaat. <br />
            Als je al wat gegevens in je GA property hebt, zal het er doorheen lopen en controleren op overeenkomsten in de
            historische gegevens. <br />
            Als je geen overeenkomsten hebt, maar weet dat er enkele moeten zijn, heb je waarschijnlijk een fout gemaakt bij
            het specificeren van de pagina paden.
            <br /> Dit is dus een goede plek om te dubbelchecken of de regels werken, voordat je de doelconfiguratie voltooit.
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/set-up-google-analytics-goals.png"
          alt="Google Analytics doelen instellen screenshot"
          className="article-img"
        />

        <p>
          Gefeliciteerd! Je hebt zojuist je doel ingesteld en kunt doelconversies voortaan volgen! <br />
          Doelen moeten worden ingesteld voor de belangrijkste conversies van je website. Als andere conversies minder
          cruciaal zijn of misschien niet worden weergegeven met hun eigen pagina pad, kun je Google Analytics
          events instellen.
          <br />
          Nadat je een event hebt ingesteld, kun je het gebruiken om je doelconversies erop te baseren.
        </p>

        <H as="h2">Events instellen in Google Analytics</H>
        <p>
          Het proces van <Link to="/nl/event-tracking">events instellen in Google Analytics</Link> kan er voor elk event heel anders uitzien. Afhankelijk van de interactie en de technologie van de website, zijn er verschillende stappen te nemen. Hieronder vind je een lijst met verschillende voorbeelden van event tracking.
        </p>
        <H as="h3">Voorbeelden van event tracking</H>

        <ul>
          <li>je content delen op sociale media</li>
          <li>de video op je voorpagina afspelen</li>
          <li>de terugknop naar de Google zoekresultaten gebruiken</li>
          <li>naar de onderkant van je artikel scrollen</li>
          <li>op je contact e-mail klikken</li>
          <li>een product aan het winkelmandje toevoegen</li>
          <li>een kleurvariant van een product selecteren</li>
        </ul>

        <p>
          De procedure voor het instellen van scroll tracking is anders dan voor het volgen van muisklikken. Op dynamische
          websites zijn HTML-elementen niet altijd zichtbaar. Daarom zou kliktracking een andere aanpak vereisen dan op statische websites. <br />
          Het punt is, er zijn veel beïnvloedende factoren, dus ik heb een apart artikel over event tracking geschreven. Daarin ga ik stap voor stap in detail en leg ik uit hoe je knopklikken of andere elementen kunt volgen. Bekijk het hier:
        </p>
        <p>
          <Link to="/nl/event-tracking">Event tracking in Google Analytics</Link>
        </p>
        <H as="h2">Campagne tracking instellen in Google Analytics</H>
        <p>
          Campagne tracking is al ingebouwd in elke Google Analytics Setup. Je hoeft alleen maar <Link to="/nl/wiki-analytics/utm-parameter">UTM parameters</Link> toe te voegen
          aan de links in je e-mail- of social media campagnes.
        </p>
        <p>
          Er zijn vijf parameters die je aan elke link die naar je website verwijst kunt toevoegen. De ingebouwde Google
          Analytics campagne tracking herkent een gebruiker van zo'n link. Het attribueert vervolgens het bezoek aan de
          gespecificeerde campagne en je kunt het terugvinden in het campagnerapport.
        </p>
        <p>
          Laten we de URL <code>https://www.jouwsite.com/campagne</code>
          <br />
          en de volgende campagnetrackingparameters als voorbeeld nemen:
        </p>

        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>UTM Parameter</th>
              <th>Parameterwaarde</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>UTM Source</td>
              <td>Medium</td>
            </tr>
            <tr>
              <td>UTM Medium</td>
              <td>Gastbericht</td>
            </tr>
            <tr>
              <td>UTM Campaign</td>
              <td>Voorjaarsuitverkoop</td>
            </tr>
            <tr>
              <td>UTM Term</td>
              <td>Laarzen</td>
            </tr>
            <tr>
              <td>UTM Content</td>
              <td>Laarzen wanneer het buiten warm is</td>
            </tr>
          </tbody>
        </table>

        <p>
          Dit is de <strong>zelfde URL inclusief UTM-parameters</strong>:
        </p>
        <p>
          <code>
            https://www.jouwsite.com/campagne?<strong>UTM_source</strong>
            =medium&amp;<strong>UTM_medium</strong>
            =gastbericht&amp;<strong>UTM_campaign</strong>=voorjaarsuitverkoop&amp;
            <strong>UTM_term</strong>=laarzen&amp;<strong>UTM_content</strong>
            =laarzen-wanneer-het-warm-is
          </code>
        </p>
        <p>
          Je kunt het gebruiken op sociale media of op andere bronnen die naar je site linken. Google Analytics volgt automatisch hoeveel klikken je vanuit zo'n link ontvangt.
        </p>
        <p>
          Merk je de <strong>vijf UTM-parameters</strong> in de URL op? Je hoeft die niet handmatig toe te voegen. Er zijn{" "}
          <a
            href="https://ga-dev-tools.appspot.com/campaign-URL-builder/"
            target="_blank"
            title="Tool om links te bouwen met UTM-parameters"
          >
            UTM Builder tools
          </a>{" "}
          die dat voor je doen. Je geeft ze gewoon de <strong>link naar je website</strong> en waarden voor de{" "}
          <strong>UTM-parameters</strong>. Daarna geven ze je de campagne tracking link terug, inclusief alle UTM-parameters.
        </p>
        <H as="h2">Google Analytics Best Practices</H>
        <p>
          Hieronder staat een lijst met aanvullende <strong>best practices</strong> voor het instellen van Google Analytics. Ik pas elk van deze rigoureus toe voor elke GA setup die ik configureer. Ze zijn niet alleen <em>prettig om te hebben</em>, maar eerder noodzakelijk om de datakwaliteit te maximaliseren. <br />
          Filters kunnen ook worden ingesteld in het tag management systeem. In feite geef ik er de voorkeur aan, omdat JavaScript geavanceerdere regels toestaat bij het filteren. Desalniettemin zijn filters in Google Analytics eenvoudiger en doen ze bijna net zo goed hun werk.
        </p>
        <ol start="1">
          <li>
            <H as="h3">Sluit eigen verkeer uit van Google Analytics</H>
            Nu je bezoeken volgt, sluit je eigen sessies uit van je websiteverkeer. Je browsegedrag zal verschillen van reguliere gebruikers en anders je gegevens vertekenen. Dit geldt vooral voor pagina's met weinig verkeer, aangezien eigen paginaweergaven zwaarder wegen in het geheel.
            <br />
            Om <strong>je eigen verkeer uit te sluiten</strong> kun je jezelf eruit filteren op <strong>IP-adres</strong>, als je een statisch IP-adres hebt.
            <br />
            Zo niet, dan kun je overwegen om er een te krijgen van je internetprovider, aangezien ze meestal niet veel kosten.
          </li>
        </ol>
        <ul>
          <li>
            Om <strong>IP-uitsluiting in Google Analytics in te stellen</strong>, ga naar het <strong>Admin-paneel</strong> en
            klik op <strong>Filters</strong> in de kolom <strong>weergave</strong> aan de rechterkant.
          </li>
          <li>
            Voeg een <strong>naam</strong> toe voor je filter en selecteer het <strong>filtertype</strong> als{" "}
            <strong>custom</strong>.
          </li>
          <li>
            Kies de filteroptie <strong>uitsluiten</strong> en selecteer <strong>IP-adres</strong> in het dropdownmenu.
          </li>
          <li>
            Voer vervolgens je statische IP in het veld <strong>filterpatroon</strong> in en klik op <strong>opslaan</strong>.
          </li>
        </ul>

        <ImgContainerFixed width="415px">
          <ImgScreenshot
            src="google-analytics-setup/exclude-ip-from-google-analytics-setup.png"
            alt="IP uitsluiten van Google Analytics setup screenshot"
            className="article-img"
          />
        </ImgContainerFixed>

        <ol start="2">
          <li>
            <H as="h3">Pagina paden omzetten naar kleine letters</H>
            Een andere aanpassing is om automatisch je <strong>pagina pad naar kleine letters om te zetten</strong>.
            <br />
            Mensen maken fouten en op een gegeven moment zal iemand een URL maken met hoofdletters in de naam. Als
            de website vervolgens dezelfde inhoud retourneert, als voor dezelfde URL met kleine letters, wordt het rommelig.{" "}
            <br /> Laat me het uitleggen. <br />
            We sturen in feite gegevens onder twee verschillende pagina paden, hoewel de gegevens voor dezelfde pagina zijn.
            <br />
            Pagina paden die in verschillende gevallen zijn geschreven, leiden tot rommelige analyses in Google Analytics. Elke unieke schrijfwijze van hetzelfde pad wordt in zijn eigen rij weergegeven in de rapporten. Na een tijdje kijk je naar meerdere rijen en hun statistieken, hoewel ze gecombineerd zouden moeten zijn. <br />
            Als je dan ook nog backlinks verdient naar dezelfde pagina, maar het adres in de link was op een grappige manier geschreven, is de chaos compleet. Dus laten we proberen ons leven drama-vrij te houden en dit van tevoren op te lossen.
          </li>
        </ol>
        <ul>
          <li>
            Om <strong>URL-paden om te zetten naar kleine letters</strong> bezoek je opnieuw de <strong>Filters</strong> voor je weergave
            en voeg je een nieuw filter toe. Geef het een naam en selecteer het <strong>custom</strong> filtertype.
          </li>
          <li>
            Kies nu <strong>Lowercase</strong> en selecteer <strong>Request URI</strong> in het veld dropdown.
          </li>
        </ul>

        <ImgContainerFixed width="369px">
          <ImgScreenshot
            src="google-analytics-setup/set-up-lowercase-urls-in-google-analytics.png"
            alt="Kleine letters URLs instellen in Google Analytics"
            className="article-img"
          />
        </ImgContainerFixed><br />

        <ul>
          <li>
            Klik op <strong>opslaan</strong> om te voltooien. Eenvoudige oplossing, die veel hoofdpijn bespaart.
          </li>
        </ul>
        <ol start="3">
          <li>
            <H as="h3">Google Analytics weergavestructuur</H>
            <p>
              Een <strong>weergave</strong> is waar je al je Google Analytics-rapporten opent. Als er filters zijn ingesteld
              voor je weergave, worden alle rapporten dienovereenkomstig gefilterd.
              <br />
              Je <strong>weergavestructuur</strong> moet meestal de structuur van je organisatie nabootsen. Als je doelmarkten
              bijvoorbeeld eerder regionaal dan landelijk zijn, stel dan je weergaven op dezelfde manier in. Dit komt van pas
              wanneer KPI's voor regio's direct toegankelijk zijn.
              <br />
              Een andere oriëntatie is de structuur van je organisatie voor budgettering en marketinginspanningen. Als dit op
              landenniveau gebeurt, is het waarschijnlijk een goed idee om die structuur ook voor de weergaven te volgen.
              <br />
              Je kunt maximaal 25 weergaven per property maken. Dus, afhankelijk van het aantal markten en marketingkanalen,
              zijn verschillende weergavestructuren mogelijk.
            </p>
            <H as="h3">Voorbeeld: Google Analytics weergavestructuur voor lokale websites</H>
            <p>
              1.0 Master
              <br />
              2.0 Intern verkeer
              <br />
              3.0 Ruwe data
            </p>
            <p>
              Hoe dan ook, er zijn meestal 3 soorten weergaven. Ze kunnen verder worden gefilterd op land
              of apparaat, als dat zinvol is voor je organisatie.
            </p>
            <H as="h3">Voorbeeld: Google Analytics weergavestructuur voor multinationale websites</H>
            <p>
              1.1 Master (VS)
              <br />
              1.2 Master (VK)
              <br />
              1.3 Master (DE)
              <br />
              1.4 Master (FR)
              <br />
              1.5 Master (ES)
              <br />
              1.6 Master (MX)
              <br />
              1.7 Master (RUS)
              <br />
              2.0 Intern verkeer
              <br />
              3.0 Ruwe data
            </p>

            <H as="h3">Voorbeeld: Google Analytics regionale weergavestructuur</H>
            <p>
              1.0 Master (NA)
              <br />
              1.1 Master (EMEA)
              <br />
              1.2 Master (LATAM)
              <br />
              1.3 Master (APEC)
              <br />
              2.0 Intern verkeer
              <br />
              3.0 Ruwe data
            </p>

            <p>
              De <strong>Master weergave</strong> is de primaire weergave voor analyse en sluit al het interne verkeer uit.
            </p>
            <p>
              De <strong>Interne verkeersweergave</strong> omvat al het interne verkeer. Deze kan ook worden gebruikt voor
              debugging en testdoeleinden. Als je het include-filter instelt, wordt al het andere automatisch uitgesloten.
            </p><p>
              In het begin creëert Google Analytics een eerste <strong>All Web Site Data weergave</strong>, samen
              met de GA-property.
            </p><p>
              Bewaar het, maar verander de naam in <strong>Ruwe Data</strong>. Je wilt een weergave met ruwe data zonder enige
              filters. Je kunt een filter niet retroactief ongedaan maken en naar de ongefilterde gegevens kijken. Daarom is het een best practice om één weergave onaangetast te houden. Om de naam te wijzigen, ga naar{" "}
              <strong>Admin &gt; Weergave &gt; Weergave-instellingen</strong>.
            </p>
            <p>
              In de meeste gevallen richten bedrijven hun inspanningen op één of een paar landen. In dat geval maak je weergaven per land aan en stel je filters dienovereenkomstig in.
              <br />
              Je kunt ook weergaven maken per verkeersbron (organisch, betaald, direct, sociaal, enz.) of apparaat (desktop,
              tablet, mobiel). Ik gebruik Google Analytics-segmenten daarvoor, maar er is geen reden om de specificaties niet al in de weergave te filteren. Ik houd echter graag het aantal weergaven laag, omdat dat de GA-gebruikersinterface schoon houdt.
              <br />Ik kan dan wat rommeliger worden met segmenten in de weergave en de overige specificaties daar toepassen.
            </p>
            <p>
              Maak alle weergaven zo vroeg mogelijk aan, want ze tonen alleen gegevens vanaf het moment dat ze zijn aangemaakt. Een
              nieuwe weergave toont geen historische gegevens van voor de aanmaakdatum. Als je terug in de tijd moet kijken met specifieke filters, val je terug op de ruwe data weergave. Je past gewoon de beoogde filters toe met segmenten in plaats daarvan. Zo krijg je je inzicht, maar verander je de gegevens niet.
            </p>
            <p>
              <strong>TIP</strong>: Als sommige weergaven dezelfde filters delen, kun je er één maken en deze vervolgens dupliceren
              samen met alle filters. Voeg daarna gewoon de weergavespecifieke filters toe aan de duplicaten.
              <br />
              Zorg ervoor dat je de oorspronkelijke weergave ongewijzigd houdt, zodat je alleen filters toevoegt aan de duplicaten. Het later wijzigen van de oorspronkelijke weergave heeft namelijk ook invloed op de gedupliceerde weergaven.
            </p>
          </li>
          <li>
            <H as="h3">Google Analytics Alerts</H>
            Een andere best practice is om <strong>Google Analytics alerts in te stellen</strong>. Ik stel het in om meldingen te krijgen
            voor afnames in sessies of wanneer de serverresponstijd toeneemt. Zo kan ik de redenen onderzoeken
            en eventueel actie ondernemen.
          </li>
        </ol>
        <ul>
          <li>
            Om een waarschuwing in Google Analytics in te stellen, ga naar <strong>Customization</strong> in het zijmenu.
          </li>
          <li>
            Als alternatief kun je ook opnieuw naar het <strong>Admin</strong> gebied gaan en klikken op{" "}
            <strong>Custom Alerts</strong> in het weergavepaneel.
          </li>
          <li>
            Klik op <strong>Custom Alerts</strong> en klik op <strong>manage customized alerts</strong>
          </li>
          <li>
            Het configureren van een Google Analytics-waarschuwing voor afnemende sessies is vrij eenvoudig. Klik op{" "}
            <strong>New Alert</strong> en geef de waarschuwing bijvoorbeeld de naam <strong>Session decrease</strong> met de{" "}
            <strong>Period</strong> ingesteld op <strong>Week</strong>.
          </li>
          <li>Vink ook het vakje aan om een e-mail te ontvangen wanneer de waarschuwing wordt geactiveerd.</li>{" "}
          <li>
            Er zijn meestal verkeersschommelingen tussen weekdagen, weekenden en nationale feestdagen. We willen niet
            worden gewaarschuwd voor die schommelingen, dus stellen we het in om wekelijkse sessiegegevens te bekijken.
          </li>
          <li>
            De <strong>Alert Conditions</strong> zijn van toepassing op <strong>al het verkeer</strong> en je wilt worden gewaarschuwd
            wanneer <strong>sessies met meer dan 35% afnemen vergeleken met de vorige week</strong>.
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/set-up-google-analytics-alert-session-decrease.png"
          alt="Google Analytics waarschuwing instellen voor afname van sessies screenshot"
          className="article-img"
        />

        <p>
          Klik daarna op <strong>opslaan</strong> en laten we de volgende waarschuwing toevoegen.
        </p>
        <p>Om een Google Analytics-waarschuwing in te stellen voor een toenemende serverresponstijd,</p>
        <ul>
          <li>
            klik op <strong>New Alert</strong> en geef het een naam, zoals{" "}
            <em>Gem. serverresponstijd neemt toe van dag tot dag</em> en
          </li>
          <li>
            selecteer <strong>dag</strong> als de periode.
          </li>
          <li>
            Voor de <strong>alert conditions</strong>, is het van toepassing op <strong>al het verkeer</strong> en je wilt worden
            gewaarschuwd wanneer je <strong>gem. serverresponstijd met meer dan 300% toeneemt</strong> vergeleken met de{" "}
            <strong>vorige dag</strong>:
          </li>
        </ul>

        <ImgScreenshot
          src="google-analytics-setup/set-up-alerts-server-rsp-time-google-analytics.png"
          alt="Google Analytics waarschuwingen instellen voor serverresponstijd screenshot"
          className="article-img"
        />

        <p>
          Klik daarna op <strong>opslaan</strong>.<br />
          Nu kun je er zeker van zijn dat je een waarschuwings-e-mail ontvangt wanneer je verkeer afneemt of wanneer je server problemen heeft.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default AnalyticsSetup;
